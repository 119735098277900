<template>
  <Loading :data="[bcd]">
    <b-table-simple responsive class="m-0" v-if="bcd" striped>
      <b-tr>
        <b-th>{{ $t('views.dnsvs.bcd_records.supervisors_admins') }}</b-th>
        <b-td>
          <template v-if="ous.length > 0">
            {{ $t('views.dnsvs.bcd_records.by_higher_level_OU') }}
            <ul>
              <OUMgrTreeEntry v-for="pou in parent_ou_tree_roots" v-bind:key="pou.short_name" :ou="pou"
                              :ous_by_parent="ous_by_parent" :parent_oe2mgr="parent_oe2mgr"
                              :parent_oe_mgr="parent_oe_mgr"
                              :direct_bcd2ous_by_ou="direct_bcd2ous_by_ou"
                              :current_bcd="bcd"/>
            </ul>
          </template>
          <p v-else class="font-italic">
            {{ $t('views.dnsvs.bcd_records.no_OU_assigned') }}
          </p>
          <template v-if="bcd_groups.length > 0">
            {{ $t('views.dnsvs.bcd_records.through_group_assignments') }}
            <ul>
              <li v-for="grp in bcd_groups" v-bind:key="grp.group_name">
                <b :title="grp.group_name in groups?groups[grp.group_name].description:''">
                  <b-link :to="'/cntl/groups/'+encodeURIComponent(grp.group_name)">{{ grp.group_name }}</b-link>
                </b>
                <b-link class="ml-1"
                        v-if="grp.group_name in group2mgrs && group2mgr_emails(grp.group_name).length > 0"
                        :href="`mailto:${group2mgr_emails(grp.group_name).join(';')}?subject=${
                                      $t('views.dnsvs.bcd_records.group_mail_template',
                                      {group_name: grp.group_name, name: bcd.name})
                                    }`">
                  <netvs-icon icon="mail"></netvs-icon>
                </b-link>
                <b-badge class="ml-1" v-if="grp.group_name in groups && groups[grp.group_name].is_admin"
                         variant="danger">
                  {{ $t('system.admin') }}
                </b-badge>
                <b-badge class="ml-1" v-if="grp.group_name in groups && groups[grp.group_name].is_own"
                         variant="success">
                  {{ $t('system.member') }}
                </b-badge>
                <b-badge @click="join_group(grp.group_name)" v-if="grp.group_name in groups && groups[grp.group_name].is_admin && !groups[grp.group_name].is_own" class="ml-1">
                  <NetvsIcon icon="group_join" />
                </b-badge>
                <b-badge class="ml-1" v-if="groups[grp.group_name] && groups[grp.group_name].description"
                         variant="warning">
                  <span :title="$t('system.description')"><NetvsIcon icon="info" class="mr-1" />{{ groups[grp.group_name].description }}</span>
                </b-badge>
                <ul v-if="grp.group_name in group2mgrs">
                  <li v-for="mgr in group2mgrs[grp.group_name]" v-bind:key="mgr.mgr_login_name">
                    <MgrLine :mgr="group_mgrs[mgr.mgr_login_name]" />
                  </li>
                </ul>
              </li>
            </ul>
          </template>
          <p v-else class="font-italic">
            {{ $t('views.dnsvs.bcd_records.no_group_assigned') }}
          </p>
        </b-td>
      </b-tr>
      <b-tr>
        <b-th>{{ $tc('system.domain', 2) }}</b-th>
        <b-td>
          {{ $t('views.dnsvs.bcd_records.through_group_assignments') }}
          <ul>
            <li v-for="grp in bcd_groups" v-bind:key="grp.group_name">
              <b :title="grp.group_name in groups?groups[grp.group_name].description:''">
                <b-link :to="'/cntl/groups/'+encodeURIComponent(grp.group_name)">{{ grp.group_name }}</b-link>
              </b>
              <b-link class="ml-1"
                      v-if="grp.group_name in group2mgrs && group2mgr_emails(grp.group_name).length > 0"
                      :href="`mailto:${group2mgr_emails(grp.group_name).join(';')}?subject=${
                                      $t('views.dnsvs.bcd_records.group_mail_template',
                                      {group_name: grp.group_name, name: bcd.name})
                                    }`">
                <netvs-icon icon="mail"></netvs-icon>
              </b-link>
              <b-badge class="ml-1" v-if="grp.group_name in groups && groups[grp.group_name].is_admin"
                       variant="danger">
                {{ $t('system.admin') }}
              </b-badge>
              <b-badge class="ml-1" v-if="grp.group_name in groups && groups[grp.group_name].is_own"
                       variant="success">
                {{ $t('system.member') }}
              </b-badge>
              <b-badge class="ml-1" v-if="groups[grp.group_name] && groups[grp.group_name].description"
                       variant="warning">
                <span :title="$t('system.description')"><NetvsIcon icon="info" class="mr-1" />{{ groups[grp.group_name].description }}</span>
              </b-badge>
              <ul>
                <li v-for="fqdn in fqdns2group[grp.group_name]" v-bind:key="fqdn.fqdn_value">
                  <b-link :to="'/dnsvs/fqdns/'+fqdn.fqdn_value">{{ fqdn.fqdn_value }}</b-link>
                </li>
              </ul>
            </li>
          </ul>
        </b-td>
      </b-tr>
    </b-table-simple>
    <APIObjectDBEditor modal_id="grp_join_modal" object_function="create"
                       object_fq_name="cntl.mgr2group"
                       :presets="db_editor_presets"
                       :non_optionals_order="db_editor_non_optionals_order"
                       object_title="" />
  </Loading>
</template>

<script>
import RangeService from '@/api-services/dns_bcd.service'
import apiutil from '@/util/apiutil'
import OUMgrTreeEntry from '@/components/OUMgrTreeEntry.vue'
import Loading from '@/components/Loading.vue'
import NetvsIcon from '@/icons/NETVSIcon.vue'
import MgrLine from '@/components/MgrLine.vue'
import APIObjectDBEditor from '@/components/db-editor/APIObjectDBEditor.vue'

export default {
  name: 'BCDRights',
  components: { APIObjectDBEditor, MgrLine, NetvsIcon, OUMgrTreeEntry, Loading },
  data() {
    return {
      bcd: null,
      ous: null,
      ous_by_parent: null,
      parent_ou_tree_roots: null,
      direct_bcd2ous_by_ou: null,
      bcd_groups: null,
      group2mgrs: null,
      fqdns2group: null,
      group_mgrs: null,
      loaded: null,
      groups: null,
      parent_oe2mgr: null,
      parent_oe_mgr: null,
      db_editor_presets: {},
      db_editor_non_optionals_order: ['group_name', 'mgr_login_name']
    }
  },
  props: {
    bcd_name: {
      type: String,
      required: true
    }
  },
  async created() {
    await this.fetchData()
  },
  methods: {
    async fetchData() {
      this.loaded = null
      const rangeResponse = await RangeService.getDetail(this.$store.state, this.bcd_name)
      console.log('Range response: ', rangeResponse)
      if (rangeResponse.data.bcd_list.length === 0) {
        await this.$router.replace('/404')
        return
      }
      this.bcd = rangeResponse.data.bcd_list[0]
      this.bcd_seclvl_by_value = apiutil.dict_by_value_of_array(rangeResponse.data.bcd_seclvl_list, 'value')
      this.bcd_categ_by_name = apiutil.dict_by_value_of_array(rangeResponse.data.bcd_categ_list, 'name')
      this.vni = rangeResponse.data.vxlan_list[0]
      this.ous = rangeResponse.data.unit_list
      this.bcd_groups = rangeResponse.data.bcd2group_list
      this.group2mgrs = apiutil.dict_of_lists_by_value_of_array(rangeResponse.data.mgr2group_list, 'group_name')
      this.group_mgrs = apiutil.dict_by_value_of_array(rangeResponse.data.mgr_list, 'login_name')
      this.fqdns2group = apiutil.dict_of_lists_by_value_of_array(rangeResponse.data.fqdn2group_list, 'group_name')
      this.groups = apiutil.dict_by_value_of_array(rangeResponse.data.mgr_groups, 'name')
      this.parent_oe2mgr = apiutil.dict_of_lists_by_value_of_array(rangeResponse.data.mgr2ou_list, 'ou_short_name')
      this.parent_oe_mgr = apiutil.dict_by_value_of_array(rangeResponse.data.ou_mgrs, 'login_name')
      this.ous_by_parent = apiutil.dict_of_lists_by_value_of_array(this.ous, 'parent_short_name')
      this.parent_ou_tree_roots = this.ous.filter(t => t.tree_level === 0)
      this.direct_bcd2ous_by_ou = apiutil.dict_by_value_of_array(rangeResponse.data.bcd2ou_list, 'ou_short_name')
      this.loaded = true
      this.$store.commit('setNavigationRefreshHandle', { gpk: this.bcd.gpk, objType: 'nd.bcd' })
    },
    group2mgr_emails(group_name) {
      const emails = []
      if (!(group_name in this.group2mgrs)) {
        this.group2mgrs[group_name] = []
      }
      this.group2mgrs[group_name].filter(group2mgr => this.group_mgrs[group2mgr.mgr_login_name])
        .filter(group2mgr => this.group_mgrs[group2mgr.mgr_login_name].email)
        .forEach(group2mgr => {
          emails.push(this.mgrs_mail(group2mgr.mgr_login_name))
        })
      return emails
    },
    mgrs_mail(user_name) {
      return `${this.group_mgrs[user_name].first_name} ${this.group_mgrs[user_name].last_name}<${this.group_mgrs[user_name].email}>`
    },
    join_group(group_name) {
      this.db_editor_presets = {
        group_name: group_name,
        mgr_login_name: this.$store.state.impersonate_user || this.$store.state.user.login_name
      }
      this.$root.$emit('bv::show::modal', 'grp_join_modal')
    }
  }
}
</script>
