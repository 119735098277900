<template>
  <div>
    <div class="container-fluid">
      <b-row>
        <b-col>
          <h1>
            {{ $route.params.bcd_name }} <small class="text-muted">{{ $t('system.broadcast_domain') }}
            <b-badge variant="success" :title="$t('views.dnsvs.bcd_records.both_subnets')"
                     pill
                     v-if="false/*has_v4 && has_v6*/">{{ $t('system.dualstack') }}
            </b-badge>
          </small>
          </h1>
        </b-col>
        <b-col>
          <div class="float-right" style="margin-bottom: 1.5rem">
            <b-button-group>
              <b-button variant="outline-secondary" @click="$bvModal.show('evlog_bcd')">
                {{ $t('system.event_log') }}
                <netvs-icon icon="evlog"></netvs-icon>
              </b-button>
              <b-button variant="outline-primary" @click="editBCD">
                {{ $t('system.utils.update_template', {object: $tc('system.bcd', 1)}) }}
                <netvs-icon icon="edit"></netvs-icon>
              </b-button>
            </b-button-group>
          </div>
        </b-col>
      </b-row>
    </div>
    <div style="margin-bottom: 1.5rem">
    </div>
    <b-card class="shadow mb-4" no-body>
      <b-card-header v-b-toggle:overview-collapse class="collapse-header">
        <h5 class="d-inline">{{ $t('views.dnsvs.bcd_records.broadcast_domain') }}</h5>
        <netvs-icon class="collapse-icon" icon="collapse"></netvs-icon>
      </b-card-header>
      <b-card-body body-class="p-0" v-if="bcd">
        <b-collapse id="overview-collapse" visible>
          <b-table-simple responsive class="m-0" striped>
            <b-tr>
              <b-th>
                {{ $t('components.bcd_list.bcd_id') }}
              </b-th>
              <b-td>
                <BCDId :bcd_vlans="vlans" :bcd="bcd" :bcd_vni="vni" :netcmpnds_by_name="netcmpnds_by_name" :netcmpnds_by_net_instnc="netcmpnds_by_net_instnc"/>
              </b-td>
            </b-tr>
            <b-tr>
              <b-th>
                {{ $t('views.dnsvs.bcd_records.broadcast_domain_name') }}
              </b-th>
              <b-td>
                {{ bcd.name }}
              </b-td>
            </b-tr>
            <b-tr>
              <b-th>{{ $t('views.home.bcd_seclvl') }}</b-th>
              <b-td>
                <p>{{ bcd_seclvl_by_value[bcd.seclvl].value }} – {{
                    $t_netdb(bcd_seclvl_by_value[bcd.seclvl].description)
                  }}</p>
              </b-td>
            </b-tr>
            <b-tr>
              <b-th>{{ $t('views.home.bcd_categ') }}</b-th>
              <b-td>
                <p>{{ bcd_categ_by_name[bcd.categ].name }} – {{
                    $t_netdb(bcd_categ_by_name[bcd.categ].description)
                  }}</p>
              </b-td>
            </b-tr>
            <b-tr>
              <b-th>
                {{ $t('system.description') }}
              </b-th>
              <b-td>
                <p>{{ bcd.description }}</p>
                <b-alert variant="dark" show v-if="bcd.admin_description">
                  <b>{{ $t('system.admin_description') }}:</b> {{bcd.admin_description}}
                </b-alert>
              </b-td>
            </b-tr>
            <b-tr>
              <b-th>
                {{ $tc('system.subnet', subnets.length) }}
              </b-th>
              <b-td>
              <span v-for="subnet in subnets" :key="subnet.cidr"><SubnetInfo
                  :subnet="subnet"></SubnetInfo><br/></span>
              </b-td>
            </b-tr>
            <b-tr>
              <b-th>{{ $t('views.dnsvs.bcd_records.natvs_section') }}</b-th>
              <b-td>
                <b-link
                    target="_blank"
                    :href="$store.state.sysinfo.netdb_admin_base + `/~netadmin/natvs/user/wrapper.cgi/${bcd.name}/0/${bcd.name}/0/`"
                >{{ bcd.name }}
                </b-link>
              </b-td>
            </b-tr>
            <b-tr>
              <b-th>{{ $tc('system.gateway', 2) }}</b-th>
              <b-td>
              <span v-for="subnet in subnets" v-bind:key="subnet.default_gateway">
                <template v-if="subnet.default_gateway">
                <code class="mr-2">{{ subnet.default_gateway }}</code>
              <b-dropdown variant="light" boundary="window">
                <template v-slot:button-content>
                  <netvs-icon icon="info"></netvs-icon>
                </template>
                <b-dropdown-item :to="'/dnsvs/ips/' + subnet.default_gateway + '/records'">
                  {{ $t('views.dnsvs.bcd_records.records_to_ip') }}
                </b-dropdown-item>
              </b-dropdown>
              <br/>
                </template>
              </span>
              </b-td>
            </b-tr>
            <b-tr v-if="Object.values(do_1_n_nat).filter(n => n.value === true).length > 0">
              <b-th>{{ $tc('system.nat_src', Object.values(do_1_n_nat).filter(n => n.value === true).length) }}</b-th>
              <b-td>
              <span v-for="subnet in subnets" v-bind:key="subnet.default_gateway">
                <template v-if="do_1_n_nat[subnet.gpk].value || false">
                <template v-if="subnet.gpk in nat_src_ips">
                  <code class="mr-2" v-for="src_ip in nat_src_ips[subnet.gpk].value" v-bind:key="src_ip">{{ src_ip }}</code>
                </template>
                <code class="mr-2" v-else-if="nat_src_ip[subnet.gpk]">{{ nat_src_ip[subnet.gpk].value }}</code>
                </template>
              </span>
              </b-td>
            </b-tr>
          </b-table-simple>
        </b-collapse>
      </b-card-body>
    </b-card>
    <DBEditor v-if="bcd" :presets="bcd" modal_id="edit_bcd"
              :object_title="bcd.name"
              object_function="update" object_fq_name="nd.bcd" :old_data="bcd"
              :non_optionals_order="['description']">
    </DBEditor>
    <EVLogViewer v-if="bcd" modal_id="evlog_bcd" :title="'BCD \''+ bcd.name + '\''"
                 ref_obj_fq="nd.bcd" :refobj_id_value="bcd.gpk"></EVLogViewer>
  </div>
</template>

<script>

import RangeService from '@/api-services/dns_bcd.service'
import apiutil from '@/util/apiutil'
import SubnetInfo from '@/components/SubnetInfo.vue'
import BCDId from '@/components/BCDId.vue'
import EVLogViewer from '@/components/EVLogViewer.vue'
import DBEditor from '@/components/db-editor/APIObjectDBEditor.vue'

export default {
  name: 'NetDocBCDDetails',
  components: {DBEditor, EVLogViewer, BCDId, SubnetInfo},
  data() {
    return {
      filter: {},
      vlans: null,
      user_possible_record_types: [],
      loaded: null,
      per_page: 50,
      current_page: {},
      full_create_record_reducer: undefined,
      full_edit_fqdn_reducer: undefined,
      db_editor_function: 'create',
      db_editor_presets: {},
      db_editor_old_data: {},
      bcd: null,
      subnets: null,
      vni: null,
      bcd_seclvl_by_value: {},
      bcd_categ_by_name: {},
      do_1_n_nat: {},
      nat_src_ip: {},
      nat_src_ips: {},
      netcmpnds_by_net_instnc: {},
      netcmpnds_by_name: {}
    }
  },
  props: {
    bcd_name: {
      type: String,
      required: true
    }
  },
  async created() {
    await this.fetchData()
  },
  methods: {
    async fetchData() {
      this.loaded = null
      const rangeResponse = await RangeService.getDetail(this.$store.state, this.bcd_name)
      if (rangeResponse.data.bcd_list.length === 0) {
        await this.$router.replace('/404')
        return
      }
      this.bcd = rangeResponse.data.bcd_list[0]
      this.bcd_seclvl_by_value = apiutil.dict_by_value_of_array(rangeResponse.data.bcd_seclvl_list, 'value')
      this.bcd_categ_by_name = apiutil.dict_by_value_of_array(rangeResponse.data.bcd_categ_list, 'name')
      this.vni = rangeResponse.data.vxlan_list[0]
      this.subnets = rangeResponse.data.ip_subnet_list
      this.do_1_n_nat = apiutil.dict_by_value_of_array(rangeResponse.data.nat_info.filter(e => e.ot_attr_def_key_word === 'do_1_n_nat'), 'object_gfk')
      // TODO: the singular-version can be removed once the migration is finished.
      this.nat_src_ip = apiutil.dict_by_value_of_array(rangeResponse.data.nat_info.filter(e => e.ot_attr_def_key_word === '1_n_nat_src_ip'), 'object_gfk')
      this.nat_src_ips = apiutil.dict_by_value_of_array(rangeResponse.data.nat_info.filter(e => e.ot_attr_def_key_word === '1_n_nat_src_ips'), 'object_gfk')
      this.vlans = rangeResponse.data.vlan_list
      this.loaded = true
      this.$store.commit('setNavigationRefreshHandle', {gpk: this.bcd.gpk, objType: 'nd.bcd'})
      this.netcmpnds_by_net_instnc = apiutil.dict_of_lists_by_value_of_array(rangeResponse.data.net_cmpnd2instnc_list, 'net_instnc')
      this.netcmpnds_by_name = apiutil.dict_by_value_of_array(rangeResponse.data.net_cmpnd_list, 'name')
    },
    editBCD() {
      this.$root.$emit('bv::show::modal', 'edit_bcd')
    },
  }
}
</script>

<style scoped>
.collapse-icon {
  display: inline;
  vertical-align: center;
  float: right;

  transition: transform;
  transition-duration: 250ms;
}

.not-collapsed > .collapse-icon {
  transform: rotate(-180deg);
}

.collapse-header {
  z-index: 69;
}
</style>
